<template>
  <div>
    <router-view :page="page" />
  </div>
</template>

<script>
import virtualTourApi from "@/api";

export default {
  name: "App",
  props: ["page"],
  data() {
    return {
      tours: [],
    };
  },
  mounted() {
    virtualTourApi.getAllTours().then((res) => {
      this.tours = res.data;
      if (!this.$route.params.tour) {
        this.$router.push(`/${this.$locale}/virtual-tour/${res.data[0].slug}`);
      }
    });
  },
};
</script>
