<template>
  <div class="loader__wrapper">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>


<style lang="scss" scoped>
.loader {
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  width: 50px;
  height: 50px;
  animation-delay: 1s;
  background: lighten($color-white, 10%);
  animation: loader $basic-transition-1000 infinite;

  &__wrapper {
    z-index: 4999;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba($color-accent, 0.5);

    display: grid;
    place-items: center;
    place-content: center;
  }
}

@keyframes loader {
  0%,
  80%,
  100% {
    opacity: 0.4;
    transform: scale(1);
  }
  40% {
    opacity: 0.8;
    transform: scale(1.5);
  }
}
</style>