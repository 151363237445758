var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("gallery__container " + (_vm.open ? 'gallery__container_open' : ''))},[_c('div',{staticClass:"gallery__inner",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }$event.preventDefault();return _vm.$emit('close')}}},[_c('Languages',{staticClass:"languages gui__element gui__element--top-left"}),_c('a',{staticClass:"btn--menu gallery--close gui__element gui__element--top-right",attrs:{"href":"#!","tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('close')}}},[_c('img',{attrs:{"src":"/assets/img/icon-close.svg"}})]),_c('div',{staticClass:"gallery__wrapper"},[(_vm.horizontal)?_c('Flicking',{ref:"flick",staticClass:"gallery__grid gallery",attrs:{"options":_vm.flickingOptions,"id":"gallery"}},_vm._l((_vm.panos),function(pano){return _c('div',{key:pano.slug,class:("gallery__card flicking-panel " + (_vm.active && _vm.active.slug == pano.slug ? 'active' : '')),on:{"click":function (event) {
              if (_vm.active && _vm.active.slug != pano.slug) {
                _vm.loadPano(event, pano);
              }
              _vm.$emit('close');
            }}},[_c('span',[_vm._v(_vm._s(pano.title))]),(pano.thumbnailUrl)?_c('img',{attrs:{"src":("/assets/vtours/" + (_vm.tour.slug) + "/" + (pano.thumbnailUrl)),"alt":("Thumbnail for pano " + (pano.slug))}}):_c('div',{staticClass:"placeholder"},[_c('img',{attrs:{"src":"/assets/img/logo.svg","alt":"project logo"}})]),_c('div',{staticClass:"border"})])}),0):_vm._e(),(_vm.horizontal)?_c('div',{staticClass:"flicking__controls"},[_c('a',{staticClass:"flicking__controls__control flicking__controls--prev",attrs:{"tabindex":"-1","href":"#!"},on:{"click":function($event){$event.preventDefault();return _vm.prev.apply(null, arguments)}}},[_c('img',{attrs:{"src":"/assets/img/icon-arrow.svg","alt":"Gallery arrow"}})]),_c('a',{staticClass:"flicking__controls__control flicking__controls--next",attrs:{"tabindex":"-1","href":"#!"},on:{"click":function($event){$event.preventDefault();return _vm.next.apply(null, arguments)}}},[_c('img',{attrs:{"src":"/assets/img/icon-arrow.svg","alt":"Gallery arrow"}})])]):_vm._e(),(!_vm.horizontal)?_c('div',{staticClass:"gallery__grid",attrs:{"id":"gallery"}},_vm._l((_vm.panos),function(pano){return _c('div',{key:pano.slug,class:("gallery__card " + (_vm.active && _vm.active.slug == pano.slug ? 'active' : '')),on:{"click":function (event) {
              if (_vm.active && _vm.active.slug != pano.slug) {
                _vm.loadPano(event, pano);
              }
              _vm.$emit('close');
            }}},[_c('span',[_vm._v(_vm._s(pano.title))]),(pano.thumbnailUrl)?_c('img',{attrs:{"src":("/assets/vtours/" + (_vm.tour.slug) + "/" + (pano.thumbnailUrl)),"alt":("Thumbnail for pano " + (pano.slug))}}):_c('div',{staticClass:"placeholder"},[_c('img',{attrs:{"src":"/assets/img/logo.svg","alt":"project logo"}})]),_c('div',{staticClass:"border"})])}),0):_vm._e()],1)],1),_c('div',{staticClass:"gallery__outer",on:{"click":function($event){return _vm.$emit('close')}}})])}
var staticRenderFns = []

export { render, staticRenderFns }