import Vue from "vue";
import VueRouter from "vue-router";
import * as views from "../views";
import virtualTourApi from "../api";

// Set init locale
Vue.prototype.$locale = process.env.VUE_APP_FALLBACK_LOCALE;
virtualTourApi.getAvailableLocales().then((res) => {
  const availableLocales = res.data;

  // Get locale from storage, navigator or fallback
  if (localStorage.getItem("locale")) {
    // Previously set in storage
    Vue.prototype.$locale = localStorage.getItem("locale");
  } else if (
    navigator.languages.some((navLang) => availableLocales.includes(navLang))
  ) {
    // System has a language that is supported
    let supportedLocale = navigator.languages.filter((navLang) =>
      availableLocales.includes(navLang)
    )[0];
    Vue.prototype.$locale = supportedLocale;
  }

  // Set new locale to storage
  localStorage.setItem("locale", Vue.prototype.$locale);
});

Vue.use(VueRouter);

const routes = [
  {
    path: "/:locale?/virtual-tour/:tour/:panorama?",
    name: "Tour - Virtual tour",
    component: views.Krpano,
  },
  // {
  //   path: "*",
  //   name: "Tour",
  //   component: views.Krpano,
  // },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.params.locale) {
//     Vue.prototype.$locale = to.params.locale;
//     localStorage.setItem("locale", to.params.locale);
//     next();
//   } else {
//     localStorage.setItem("locale", process.env.VUE_APP_FALLBACK_LOCALE);
//     let path = `/${
//       localStorage.getItem("locale")
//         ? localStorage.getItem("locale")
//         : process.env.VUE_APP_FALLBACK_LOCALE
//     }`;
//     window.location.href = path;
//   }
// });

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

export default router;
