<template>
  <div class="socials gui__element gui__element--top-right">
    <a
      v-if="client.facebook"
      :href="client.facebook"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img :src="`/assets/img/icon-facebook.svg`" alt="Facebook icon" />
    </a>
    <a
      v-if="client.linkedin"
      :href="client.linkedin"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img :src="`/assets/img/icon-linkedin.svg`" alt="Linkedin icon" />
    </a>
    <a
      v-if="client.youtube"
      :href="client.youtube"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img :src="`/assets/img/icon-youtube.svg`" alt="Youtube icon" />
    </a>
    <a
      v-if="client.instagram"
      :href="client.instagram"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img :src="`/assets/img/icon-instagram.svg`" alt="Instagram icon" />
    </a>
    <a
      v-if="client.twitter"
      :href="client.twitter"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img :src="`/assets/img/icon-twitter.svg`" alt="Twitter icon" />
    </a>
  </div>
</template>

<script>
export default {
  name: "Socials",
  data() {
    return {
      client: {
        facebook: process.env.VUE_APP_SOCIAL_FACEBOOK,
        twitter: process.env.VUE_APP_SOCIAL_TWITTER,
        linkedin: process.env.VUE_APP_SOCIAL_LINKEDIN,
        youtube: process.env.VUE_APP_SOCIAL_YOUTUBE,
        instagram: process.env.VUE_APP_SOCIAL_INSTAGRAM,
      },
    };
  },
};
</script>

<style lang="scss">
.socials {
  display: grid;
  flex-direction: column;
  grid-gap: 12px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: relative;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      transition: opacity $basic-transition-200;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(50deg)
        brightness(110%) contrast(100%);
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
}
</style>