const getTextWidth = (text, font) => {
  var canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  context.font = font;
  var metrics = context.measureText(text);
  return metrics.width;
};

const addExitVrHotspot = () => {
  // Create exit vr hotspot
  let spotName = `vr_exit`;
  let spot = krpanoObj.addhotspot(spotName);
  spot.loadstyle("vr_hotspot_style");
  spot.text = "Verlaat VR";
  spot.textwidth = getTextWidth("Verlaat VR", "bold 16px arial") + 64;
  spot.ath = 0;
  spot.atv = 90;
  spot.actiontype = "exit_vr";
};

const addSingleVrHotspot = (hotspot) => {
  // Create vr hotspot
  hotspot.classList.add("invisible");
  hotspot.nextElementSibling.classList.add("invisible");
  let spotName = `vr_${hotspot.dataset.slug}`;
  let spot = krpanoObj.get(`hotspot["${spotName}"]`);

  if (hotspot.dataset.type == "navigation" && !spot) {
    spot = krpanoObj.addhotspot(spotName);
    spot.loadstyle("vr_hotspot_style");
    spot.text = hotspot.dataset.title;
    spot.textwidth =
      getTextWidth(hotspot.dataset.title, "bold 16px arial") + 64;
    spot.ath = hotspot.dataset.ath;
    spot.atv = hotspot.dataset.atv;
    spot.actiontype = "navigation";
    spot.actionslug = hotspot.dataset.slug;
    spot.actionname = hotspot.dataset.name;
  }
};

const addVrHotspots = () => {
  // Copy original hotspots
  addExitVrHotspot();
  let hotspots = document.querySelectorAll(".hotspot");
  hotspots.forEach((hotspot) => {
    addSingleVrHotspot(hotspot);
  });
};

const removeVrHotspots = () => {
  let hotspots = document.querySelectorAll(".hotspot");
  hotspots.forEach((hotspot) => {
    let spotName = `vr_${hotspot.dataset.slug}`;
    krpanoObj.removehotspot(spotName);
    krpanoObj.removehotspot(`vr_${hotspot.dataset.slug}_text`);
    krpanoObj.removehotspot(`vr_${hotspot.dataset.slug}_hitarea`);
    krpanoObj.removehotspot(`vr_${hotspot.dataset.slug}_hover`);
    hotspot.classList.remove("invisible");
    hotspot.nextElementSibling.classList.remove("invisible");
  });

  // remove exit vr hotspot
  let spot = krpanoObj.removehotspot("vr_exit");
  krpanoObj.removehotspot(`vr_exit_text`);
  krpanoObj.removehotspot(`vr_exit_hitarea`);
  krpanoObj.removehotspot(`vr_exit_hover`);
};

export {
  addExitVrHotspot,
  addSingleVrHotspot,
  addVrHotspots,
  removeVrHotspots,
};
