<template>
  <div
    :class="`hotspot ${`hotspot__${hotspot.type.value}`} ${
      clickCount > 0 && isTouchDevice ? 'touched' : ''
    }`"
    :id="hotspot.id"
    :data-title="hotspot.linkedPano.title"
    :data-slug="hotspot.linkedPano.slug"
    :data-name="hotspot.linkedPano.name"
    :data-ath="hotspot.ath"
    :data-atv="hotspot.atv"
    :data-type="hotspot.type.value"
    @click="handleClick"
    v-click-outside="handleClickOutside"
  >
    <div :class="`hotspot__inner hotspot__inner__${hotspot.type.value}`">
      <img
        :src="`/assets/img/icon-${hotspot.type.value}.svg`"
        alt="hotspot icon"
      />
    </div>

    <div
      :class="`hotspot__content ${`hotspot__content--${hotspot.type.value}`}`"
    >
      <span v-if="hotspot.type.value == 'navigation'">
        {{ hotspot.linkedPano.title }}
      </span>
      <span
        v-else-if="
          hotspot.type.value == 'info' || hotspot.type.value == 'small-info'
        "
      >
        {{ hotspot.modal.title }}
      </span>
      <p
        v-if="hotspot.type.value == 'small-info' && hotspot.modal.smallContent"
        v-html="hotspot.modal.smallContent.substring(0, 250)"
      ></p>
      <div
        v-if="hotspot.type.value !== 'small-info'"
        class="hotspot__content__bg"
      ></div>
    </div>
  </div>
</template>

<script>
import { isTouchDevice } from "@/helpers";
import { addSingleVrHotspot } from "@/helpers/vr";
import vClickOutside from "v-click-outside";

export default {
  name: "Hotspot",
  props: {
    webVRActive: Boolean,
    tour: Object,
    pano: Object,
    hotspot: Object,
    clickedOutside: Boolean,
  },
  data() {
    return {
      isTouchDevice: isTouchDevice(),
      clickCount: 0,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    let hotspotDOM = document.getElementById(this.hotspot.id);
    if (this.webVRActive && hotspotDOM) {
      addSingleVrHotspot(hotspotDOM);
    }
  },
  methods: {
    handleClickOutside(event) {
      this.clickCount = 0;
      if (
        document.getElementById(this.hotspot.id) &&
        document
          .getElementById(this.hotspot.id)
          .classList.contains("hotspot__small-info")
      ) {
        document.getElementById(this.hotspot.id).classList.remove("touched");
      }
    },
    updateClasses() {
      document.querySelectorAll(".hotspot__small-info").forEach((el) => {
        el.classList.remove("touched");
      });
      document.querySelectorAll(".locator__touched").forEach((el) => {
        el.classList.remove("locator__touched");
      });
      document
        .getElementById(`hotspot__locator--${this.hotspot.id}`)
        .classList.add("locator__touched");
    },
    handleClick() {
      this.updateClasses();
      this.clickCount++;

      if (
        this.isTouchDevice &&
        this.clickCount < 2 &&
        this.hotspot.type.value !== "small-info"
      )
        return;

      let data = {
        hotspot_type: this.hotspot.type.value,
      };

      switch (this.hotspot.type.value) {
        case "navigation":
          this.$emit("navigate", this.hotspot.linkedPano);
          data.nav_from = this.pano.title;
          data.nav_to = this.hotspot.linkedPano.title;
          break;
        case "info":
          this.$emit("info", this.hotspot.modal);
          data.modal_on = this.pano.title;
          data.modal_title = this.hotspot.modal.title;
          data.modal_type = this.hotspot.modal.hasVideo
            ? "Video modal"
            : this.hotspot.modal.images && this.hotspot.modal.images.length > 0
            ? "Image modal"
            : "Text modal";
          break;
        case "small-info":
          const smallInfo = document.getElementById(this.hotspot.id);
          if (!smallInfo.classList.contains("touched")) {
            document.getElementById(this.hotspot.id).classList.add("touched");
          }
          break;
        default:
          break;
      }

      // Fire GTM event for click
      if (data) {
        this.sendGtmEvent("click-hotspot", data, this.tour.title, this.pano.title);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$hotspot-dimension: 32px;

.hotspot {
  /*
    Position on ath/atv : krpano hack
  */
  position: absolute;
  cursor: pointer !important;
  pointer-events: all;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: $hotspot-dimension;
  height: $hotspot-dimension;
  border-radius: 50px;
  background: $color-white;

  display: grid;
  place-content: center;

  box-shadow: 0 0 32px 8px rgba(0, 0, 0, 0.2);
  transition: all $basic-transition-200;

  &__inner {
    &__navigation {
      width: calc(#{$hotspot-dimension} / 3);
      height: calc(#{$hotspot-dimension} / 3);
      background: $color-primary;
      border-radius: 50px;
      transition: all $basic-transition-200;
      overflow: hidden;
      display: grid;
      place-content: center;

      img {
        opacity: 0;
        height: 100%;
        width: 100%;
        transition: all $basic-transition-200;
      }
    }

    &__info,
    &__small-info {
      width: $hotspot-dimension;
      height: $hotspot-dimension;
      border-radius: 50px;

      img {
        // opacity: 0;
        height: 100%;
        width: 100%;
        transition: all $basic-transition-200;
      }
    }
  }

  &__content {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    height: $hotspot-dimension;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 20px 0 50px;
    border-radius: 50px;
    opacity: 0;
    overflow: hidden;
    transition: all $basic-transition-200;

    span {
      opacity: 0;
      transition: all $basic-transition-1000;
    }

    &__bg {
      background: $color-primary;
      width: 0%;
      height: 100%;
      left: 0;
      transition: all $basic-transition-500;
      position: absolute;
      z-index: -1;
    }

    &--small-info {
      padding: 12px;
      flex-direction: column;
      // min-height: 200px;
      height: auto;
      border-radius: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: -1;
      border: 4px solid $color-white;
      background-color: $color-primary;
      opacity: 0;
      transition: all $basic-transition-200;

      display: flex;
      align-items: flex-start !important;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 250px;
      text-align: left;

      span {
        font-weight: bold;
        margin-bottom: 12px;
        font-size: 1.6rem;
        white-space: break-spaces;
        transition: all $basic-transition-500;
      }

      p {
        font-weight: normal;
        opacity: 0;
        font-size: 1.4rem;
        transition: all $basic-transition-500;
        white-space: break-spaces;
      }
    }

    * {
      color: $color-text-inverse;
      font-weight: bold;
      font-size: 14px;
    }
  }

  &:hover,
  &.touched {
    .hotspot__inner {
      background: darken($color-primary, 20);
      width: calc(#{$hotspot-dimension} - 6px);
      height: calc(#{$hotspot-dimension} - 6px);

      img {
        opacity: 1;
        filter: invert(92%) sepia(100%) saturate(0%) hue-rotate(202deg)
          brightness(106%) contrast(106%);
      }
    }

    .hotspot__content {
      opacity: 1;
      pointer-events: all;

      span,
      p {
        opacity: 1;
      }

      &__bg {
        width: 100%;
      }
    }
  }
}
</style>
