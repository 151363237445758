<template>
  <div class="dot__row">
    <transition name="fade-title" mode="out-in">
      <p v-if="!title" class="dot__title" key="invisible"></p>
      <p v-else class="dot__title" key="visible">{{ title }}</p>
    </transition>
    <div
      v-for="pano in panos"
      :key="pano.slug"
      class="dot__container"
      @click="
        (event) => {
          if (active && active.slug != pano.slug) {
            loadPano(event, pano);
          }
          $emit('close');
        }
      "
      @mouseover="changeTitle(pano.title)"
      @mouseout="changeTitle(null)"
    >
      <div
        :class="`dot ${
          active && active.slug == pano.slug ? 'dot--active' : ''
        }`"
      >
        <div class="dot__background"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dots",
  props: {
    panos: { default: [] },
    active: { default: null },
    loadPano: Function,
  },
  data() {
    return {
      title: null,
    };
  },
  computed: {
    tour() {
      return this.$store.state.currentTour;
    },
  },
  methods: {
    changeTitle(newTitle) {
      this.title = newTitle;
    },
  },
};
</script>

<style lang="scss" scoped>
.dot {
  display: block;
  width: 12px;
  height: 12px;
  background-color: $color-white;
  border-radius: 50%;
  position: relative;

  display: grid;
  place-content: center;

  pointer-events: none;

  &--active {
    width: 24px;
    height: 24px;
  }

  &__background {
    width: 8px;
    height: 8px;
    background-color: $color-primary;
    border-radius: 50%;
    z-index: -1;

    transition: all $basic-transition-200;
  }

  &__container {
    margin: 0 12px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: grid;
    place-content: center;
    pointer-events: all;

    &:hover .dot:not(.dot--active) .dot__background {
      width: 32px;
      height: 32px;
    }
  }

  &__row {
    display: none;
    max-width: 50%;
    min-width: 500px;

    @include for-tablet-landscape-up {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  &__title {
    color: $color-text-inverse;
    font-weight: bold;
    top: -32px;
    position: absolute;
  }
}

.fade-title-enter-active,
.fade-title-leave-active {
  transition: opacity 0.1s linear;
}
.fade-title-enter,
.fade-title-leave-to {
  opacity: 0;
}
</style>